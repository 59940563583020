import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Button from '../../../../../../../components/_atoms/Button';
import getHostnameFromUrl from '../../../../../../../utils/getHostnameFromUrl';
import getProtocolFromUrl from '../../../../../../../utils/getProtocolFromUrl';
import { ShowCodeProps } from './interface';
import styles from './styles.module.scss';
import Input from '../../../../../../../componentsV2/DesignSystem/Inputs/Input';
import Switch from '../../../../../../../componentsV2/DesignSystem/Inputs/Switch';
import Select from '../../../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import ButtonCopy from '../../../../../../../componentsV2/ButtonCopy';
import { Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';

export default function ShowPopupCode({
  toggleModal,
  promote,
  isFullScreen,
  isPositionAllowed,
  micrositeUrl,
}: ShowCodeProps): JSX.Element {
  const { t } = useTranslation('promotionsContentShare');
  const [modal, setModal] = useState<Modal | null>(null);
  const [triggerName] = useState('cta-beeliked-button');
  const modalRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState(
    isPositionAllowed ? { width: '400px', height: '600px' } : { width: '800px', height: '400px' },
  );
  const [onClick, setOnClick] = useState(0);
  const [onLeave, setOnLeave] = useState(0);
  const [onLoad, setOnLoad] = useState(0);
  const [enablePosition, setEnablePosition] = useState(0);
  const [position, setPosition] = useState('');
  const [timeoutToLoad, setTimeoutToLoad] = useState(1);
  const [preTimeoutToLoad, setPreTimeoutToLoad] = useState(1);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    const modal = new Modal(modalRef.current, { keyboard: false, backdrop: 'static' });
    setModal(modal);
    modal?.show();
  }, []);

  const hideModal = () => {
    modal?.hide();
    setModal(null);
    setTimeout(() => toggleModal(), 1000);
  };

  const url = useMemo(
    () =>
      promote.external_domain
        ? promote.external_domain
        : `${getProtocolFromUrl(micrositeUrl)}://${getHostnameFromUrl(micrositeUrl)}`,
    [micrositeUrl, promote.external_domain],
  );
  const codeContent = useMemo(() => {
    const setupPosition = (position: string) => {
      switch (position) {
        case 'top-left':
          return 'top: 1rem; left: 1rem';
        case 'top-center':
          return 'top: 1rem; left:50%; transform: translateX(-50%) ';
        case 'top-right':
          return 'top: 1rem; right: 1rem';
        case 'center-left':
          return 'top: 50%; left: 1rem; transform: translateY(-50%)';
        case 'center-right':
          return 'top: 50%; right: 1rem; transform: translateY(-50%);';
        case 'bottom-left':
          return 'bottom: 1rem; left: 1rem';
        case 'bottom-center':
          return 'bottom: 1rem; left:50%; transform: translateX(-50%)';
        case 'bottom-right':
          return 'bottom: 1rem; right: 1rem';
        default:
          return 'top: 50%; left: 50%; transform: translate(-50%, -50%)';
      }
    };
    const validations = () => {
      let value = '';
      if (!!onClick && triggerName)
        value = `${value} const trigger = get(".${triggerName}") || get("#${triggerName}");`;
      if (!!onLoad) value = `${value} setTimeout(open, ${timeoutToLoad * 1000 ?? 0});`;
      if (!!onClick && triggerName) value = `${value} trigger.addEventListener("click", open);`;
      if (!!onLeave) value = `${value} get('body').addEventListener('mouseleave', open);`;

      return value;
    };
    if (url) {
      if (promote.render === 'nextjs') {
        return `<!-- BeeLiked Microsite Code -->
<div class="beeliked-popup-overlay" style="opacity: 0; z-index: -998; transition: 0.5s;width: 100vw; height: 100vh; overflow: hidden; position: fixed; top: 0; left: 0; background: rgba(0,0,0,0.5); backdrop-filter: blur(8px)"></div>
<div class="blkd_microsite" id="blkd-microsite-${
          promote.id
        }" style="opacity: 0; transition: 0.5s; z-index: -9999;position:fixed; ${
          isFullScreen
            ? 'width: calc(100% - 4rem); max-height: calc(100% - 4rem); top: 2rem; left:2rem;'
            : `width: calc(100% - 2rem); max-width: ${size?.width}; max-height:${size?.height}; ${
                Boolean(enablePosition)
                  ? setupPosition(position)
                  : 'top: 50%; left: 50%; transform: translate(-50%, -50%)'
              };`
        } overflow: auto;">
<button id="close-button-iframe-beeliked" style="padding: 0; color: #000;opacity: 0; z-index: -99999; position:sticky; top: 1rem; left: calc(100% - 3rem); height: 2rem;width: 2rem; border: 0;background: white;font-size: 1.25rem;line-height: 0;border-radius: 50%;box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);cursor: pointer;">X</button>
</div>
<script>window.addEventListener("blkdIframeCtrlLoaded", function() { blkdIframeCtrl(document.getElementById('blkd-microsite-${
          promote.id
        }'), '${micrositeUrl}'); });</script>
<script src="${url}/embed/v1/script.js"></script>
<script>window.addEventListener("load", () => {
const get = (name) => document.querySelector(name);
const modal = get("#blkd-microsite-${promote.id}");
modal.querySelector("iframe").contentWindow.document.querySelector("body").addEventListener("click", () => modal.scroll(0,0))
const iframe = modal.querySelector("iframe")
iframe.style.top = "-2rem"
iframe.style.position = "relative"
const ov = get(".beeliked-popup-overlay")
const closeButton = get("#close-button-iframe-beeliked")
function open(){
modal.style.opacity = "1"; modal.style.zIndex = "9999"; closeButton.style.opacity = '1'; closeButton.style.zIndex = '99999'; ov.style.opacity = '1'; ov.style.zIndex = '9998';
}
function close(){
modal.style.opacity = "0"; modal.style.zIndex = "-9999"; closeButton.style.opacity = "0"; closeButton.style.zIndex = "-9999"; ov.style.opacity = "0"; ov.style.zIndex = "-9999";get('body').removeEventListener('mouseleave', open);
}
[closeButton, ov].forEach((each) => each.addEventListener("click", close));
${validations()}
})</script>
<!-- End BeeLiked Microsite Code -->`;
      }

      return `<!-- BeeLiked Microsite Code -->
      <button style="opacity: 0; z-index: -99999; position:fixed; top: 1rem; right: 1rem; height: 2rem;width: 2rem; border:0;background: white;font-size: 2rem;line-height: 0;border-radius: 50%;box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);cursor: pointer;">x</button>
<div class="blkd_microsite" id="blkd-microsite-${promote.id}" style="opacity: 0; transition: 0.5s; z-index: -9999;position:fixed; width: calc(100% - 4rem); max-height: calc(100% - 4rem); top: 2rem; left:2rem; overflow: auto; border-radius: 1rem;">
    <div class="blkd-loader" style="padding: 10px 0px; width: 100px; margin-left:-50px; background:#333;position:absolute; left:50%; top:160px; text-align:center; border-radius:5px; opacity:0.8;">
        <img src="${url}/imgs/microsites/loader-dark.gif" alt="" style="margin-bottom: 20px; width: 64px; height: 64px;">
        <span style="color:#ddd; font-size:14px">Loading...</span>
    </div>
</div>
<script src="${url}/min/beeliked.iframeCtrl3.min.js"></script>
<script>blkdIframeCtrl(document.getElementById('blkd-microsite-${promote.id}'), '${micrositeUrl}', { loaderClass: 'blkd-loader' });</script>
<!-- End BeeLiked Microsite Code -->`;
    }
  }, [url, triggerName, size, onClick, onLeave, onLoad, timeoutToLoad, position]);

  const codeOpenGraph = `<meta property="og:url" content="${micrositeUrl}" />\n<meta property="og:type" content="website" />`;

  return (
    <div className="modal fade" tabIndex={-1} ref={modalRef}>
      <div className={['modal-dialog modal-dialog-centered', styles['modal-dialog']].join(' ')}>
        <div className="modal-content">
          <div className="modal-header">
            <ul className={['breadcrumb', styles['breadcrumb']].join(' ')}>
              <li>{t('public-mode.modal-show-code.Show the embed code')}</li>
            </ul>
            <button type="button" className="btn-close" onClick={hideModal} />
          </div>
          <div className="modal-body" style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <p style={{ margin: '0 0 -1rem 0' }}>
              {t(
                'public-mode.modal-show-code.This HTML embed code will allow you to insert this campaign as an iframe in your own website and will ensure it remains responsive to different screen sizes.',
              )}
            </p>
            <div className={styles['content-share-code']}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="mb-0">
                  Copy this code to the <code>&lt;body&gt;</code> section of your web page
                </h3>
                <div className="text-nowrap ms-2">
                  <Button
                    type="button"
                    className="btn-outline-secondary"
                    size="sm"
                    onClick={() => {
                      navigator.clipboard.writeText(codeContent ?? '');
                    }}
                    disabled={triggerName.trim().length == 0 && !onClick && !onLoad && !onLeave}
                  >
                    <i className="fas fa-copy" /> {t('public-mode.modal-show-code.Copy Code')}
                  </Button>
                </div>
              </div>
              {!!codeContent && (
                <SyntaxHighlighter language="htmlbars" style={vs2015}>
                  {codeContent}
                </SyntaxHighlighter>
              )}
            </div>

            <div className={styles['content-share-code']}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="mb-0">
                  Remember to include opengraph and twitter card metadata to your page. Copy this code to the{' '}
                  <code>&lt;head&gt;</code> section of your web page
                </h3>
                <div className="text-nowrap ms-2">
                  <Button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(codeOpenGraph);
                    }}
                    className="btn-outline-secondary"
                    size="sm"
                  >
                    <i className="fas fa-copy" /> {t('public-mode.modal-show-code.Copy Code')}
                  </Button>
                </div>
              </div>
              <SyntaxHighlighter language="htmlbars" style={vs2015}>
                {codeOpenGraph}
              </SyntaxHighlighter>
            </div>

            <div className={styles['content-share-code']}>
              <div className={styles['events-wrapper']}>
                <div>
                  <h3>Use this class to open when click</h3>
                  <Paragraph size={2}>{triggerName}</Paragraph>
                  <ButtonCopy disabled={!onClick} text={triggerName} onClickMessage="Trigger class copied"></ButtonCopy>
                  <Switch value={onClick} onChange={(e) => setOnClick(Number(e.target.checked))} />
                </div>
                <div>
                  <h3>Open on load, type the timeout in seconds.</h3>
                  <Input
                    fullwidth
                    placeholder="1000"
                    value={String(preTimeoutToLoad)}
                    onChange={(e) => setPreTimeoutToLoad(Number(e.target.value))}
                    onBlur={() => {
                      Number(preTimeoutToLoad) < 1
                        ? (setTimeoutToLoad(timeoutToLoad), setPreTimeoutToLoad(timeoutToLoad))
                        : setTimeoutToLoad(preTimeoutToLoad);
                    }}
                    handleIncrement={() => setTimeoutToLoad(timeoutToLoad + 1)}
                    handleDecrement={() => setTimeoutToLoad(timeoutToLoad - 1)}
                    disabled={!onLoad}
                    zeroDisabled
                    type="number"
                  />
                  <Switch value={onLoad} onChange={(e) => setOnLoad(Number(e.target.checked))} />
                </div>
                <div>
                  <h3>Open when leave the page</h3>
                  <Switch value={onLeave} onChange={(e) => setOnLeave(Number(e.target.checked))} />
                </div>
                {!!isPositionAllowed && (
                  <div>
                    <h3>Setup the position</h3>
                    <Select
                      placeholder="Choose a position"
                      onChange={(e) => setPosition(String(e))}
                      disabled={!Boolean(enablePosition)}
                      defaultValue={position}
                      options={[
                        { value: 'top-left', label: 'Top  Left' },
                        { value: 'top-center', label: 'Top  Center' },
                        { value: 'top-right', label: 'Top  Right' },
                        { value: 'center-left', label: 'Center Left' },
                        { value: 'center-center', label: 'Center Center' },
                        { value: 'center-right', label: 'Center Right' },
                        { value: 'bottom-left', label: 'Bottom Left' },
                        { value: 'bottom-center', label: 'Bottom Center' },
                        { value: 'bottom-right', label: 'Bottom Right' },
                      ]}
                    ></Select>
                    <Switch value={enablePosition} onChange={(e) => setEnablePosition(Number(e.target.checked))} />
                  </div>
                )}
              </div>
            </div>
            {!isFullScreen && (
              <div className={styles['content-share-code']}>
                <h3 className="mb-2">Setup your popup size</h3>
                <div className={styles['inputs-wrapper']}>
                  <div>
                    <h3 className="mb-2">Width:</h3>
                    <Input
                      fullwidth
                      placeholder="800px"
                      value={size.width}
                      onChange={(e) => setSize({ ...size, width: e.target.value })}
                    ></Input>
                  </div>
                  <div>
                    <h3 className="mb-2">Height:</h3>
                    <Input
                      fullwidth
                      placeholder="500px"
                      value={size.height}
                      onChange={(e) => setSize({ ...size, height: e.target.value })}
                    ></Input>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer d-flex justify-content-end">
            <Button type="button" color="secondary" onClick={hideModal}>
              {t('public-mode.modal-show-code.Close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
