import useFetchPromotionsSimpleList from '../../../../graphql/queries/useFetchPromotionsSimpleList';
import useAccount from '../../../../hooks/useAccount';
import useQueryParams from '../../../../hooks/useQueryParams';
import useQueryString from '../../../../hooks/useQueryString';
import Filters from '../Filters';
import FILTER_LIST from '../Filters/DefaultFilterList';
import { CONTACTS_FILTER_LIST } from '../Filters/FilterList';
import Content from './Content';
import styles from './styles.module.scss';
import { ContactsFilters, useFetchContacts } from './useFetchContacts';

export function Contacts(): JSX.Element {
  const { getAccount } = useAccount();

  const query = useQueryString();
  const addQueryParam = useQueryParams();

  const queryValues = {
    sort_by: query.get('sort_by') || 'created_at',
    sort_order: query.get('sort_order') || 'desc',
    currentPage: Number(query.get('page')) || 1,
    tableColumns: query.get('columns') || undefined,
  };
  const newDate = new Date();
  const filters = {
    search: query.get('search') || undefined,
    promo_id: query.get('promo_id') || ' ',
    number_entries: query.get('number_entries') || ' ',
    created_at:
      query.get('created_at') || `2021-01-01_${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`,
  };

  const params: ContactsFilters = {
    sort_by: queryValues.sort_by,
    sort_order: queryValues.sort_order,
    ...filters,
  };

  const {
    data,
    loading: dataLoading,
    pagination,
  } = useFetchContacts(getAccount.accountId, { page: queryValues.currentPage }, params);

  const { data: promotionData, loading: promotionLoading } = useFetchPromotionsSimpleList(getAccount.accountId);

  const handlePagination = (page: number) => {
    addQueryParam({ page });
  };

  const searchEntries = (field: string, value: string) => {
    const searchFilter = value ? { [field]: value } : { [field]: '' };
    const filters = {
      ...searchFilter,
    };
    addQueryParam({ ...filters, page: 1 });
  };

  const clearUrlFilters = () => {
    addQueryParam({
      sort_by: undefined,
      sort_order: undefined,
      created_at: undefined,
      number_entries: undefined,
      promo_id: undefined,
      columns: undefined,
      search: undefined,
      page: 1,
    });
  };

  return (
    <section className={styles['wrapper']}>
      <Filters
        fetchLoading={dataLoading}
        search={searchEntries}
        params={params}
        clearUrlFilters={clearUrlFilters}
        data={promotionData}
        loadingData={promotionLoading}
        filterList={[...FILTER_LIST, ...CONTACTS_FILTER_LIST]}
        filterKey={`contacts_${getAccount.accountId}`}
      />
      <Content
        data={data}
        dataLoading={dataLoading}
        pagination={pagination}
        handlePagination={handlePagination}
        currentPage={queryValues.currentPage}
        search={searchEntries}
        params={params}
      />
    </section>
  );
}
