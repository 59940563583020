import { useState } from 'react';
import { Button } from '../../DesignSystem/Inputs/Buttons/ButtonAction';
import { TableHeaderElement } from '../interface';
import useQueryParams from '../../../hooks/useQueryParams';
import useQueryString from '../../../hooks/useQueryString';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import styles from './styles.module.scss';

export function HeaderCell({
  name,
  base_field,
  align = 'left',
  minWidth,
  is_optin,
  width,
  sortDisabled,
}: TableHeaderElement): JSX.Element {
  //@todo implement when we encrypt the data const { getAccount } = useAccount();
  //@todo implement when we encrypt the data const [showPopover, setShowPopover] = useState(false);
  const [showOptinPopover, setShowOptinPopover] = useState(false);
  const addQueryParam = useQueryParams();
  const query = useQueryString();
  const [sortColumn, setSortColumn] = useState(query.get('sort_order') ?? 'fold');

  const queryValues = {
    sort_by: query.get('sort_by') || 'created_at',
    sort_order: query.get('sort_order') || 'desc',
    currentPage: Number(query.get('page')) || 1,
    tableColumns: query.get('columns') || undefined,
  };

  const renderSortButton = (sortType: string) => {
    if (!!queryValues.sort_order && queryValues?.sort_by?.toLocaleLowerCase() != base_field?.toLocaleLowerCase()) {
      return 'unfold_more';
    }
    switch (sortType) {
      case 'asc':
        return 'keyboard_arrow_up';
      case 'desc':
        return 'keyboard_arrow_down';
      default:
        return 'unfold_more';
    }
  };
  function handleSortButton() {
    let columnIcon;
    if (queryValues?.sort_by?.toLocaleLowerCase() != base_field?.toLocaleLowerCase()) {
      columnIcon = 'desc';
      addQueryParam({ ...queryValues, sort_by: base_field, sort_order: 'desc' });
    } else {
      switch (sortColumn) {
        case 'asc':
          columnIcon = 'fold';
          addQueryParam({ ...queryValues, sort_by: base_field, sort_order: '' });
          break;
        case 'desc':
          columnIcon = 'asc';
          addQueryParam({ ...queryValues, sort_by: base_field, sort_order: 'asc' });
          break;
        default:
          columnIcon = 'desc';
          addQueryParam({ ...queryValues, sort_by: base_field, sort_order: 'desc' });
          break;
      }
    }

    setSortColumn(columnIcon);
  }

  //@todo implement when we encrypt the data const renderEyeButton = (isHide: boolean) => {
  //   if (isHide) {
  //     return 'visibility_off';
  //   } else {
  //     return 'visibility';
  //   }
  // };
  return (
    <div
      className={styleModuleClasses(styles, 'each-header', align)}
      {...(minWidth ? { style: { minWidth: minWidth } } : undefined)}
      {...(width ? { style: { width: width } } : undefined)}
    >
      <Button
        variant="tertiary"
        text={{ color: 'dark' }}
        //@todo implement when we encrypt the data {...(!!eachColumn.is_uid_field && {
        //   firstIcon: {
        //     children: renderEyeButton(!!eachColumn.is_uid_field),
        //     onMouseEnter: () => setShowPopover(true),
        //     onMouseLeave: () => setShowPopover(false),
        //     ...(getAccount.accountPermission != 'account_admin' && {
        //       style: { cursor: 'default' },
        //       color: 'gray-40',
        //     }),
        //     popover: {
        //       showPopover: showPopover,
        //       color: 'dark',
        //       placement: 'top',
        //       hiddenOverlay: true,
        //       children: 'Sorry, you need to be an admin.',
        //       propRef: null,
        //     },
        //   },
        // })}
        {...(!!is_optin && {
          firstIcon: {
            onMouseEnter: () => setShowOptinPopover(true),
            onMouseLeave: () => setShowOptinPopover(false),
            children: 'mail',
            popover: {
              showPopover: showOptinPopover,
              color: 'dark',
              placement: 'top',
              hiddenOverlay: true,
              children: 'Custom Opt in',
              propRef: null,
            },
          },
        })}
        onClick={handleSortButton}
        {...(!sortDisabled && {
          lastIcon: { children: renderSortButton(sortColumn), onClick: handleSortButton },
        })}
      >
        {name}
      </Button>
    </div>
  );
}
