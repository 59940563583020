import { FilterList } from './interface';

export const CONTACTS_FILTER_LIST: FilterList[] = [
  {
    id: 'number_entries',
    name: 'Number of Entries',
    slug: 'number_entries',
    type: 'multi',
    options: [
      { label: '01 > 02', value: '1_2' },
      { label: '03 > 05', value: '3_5' },
      { label: '06 > 10', value: '6_10' },
      { label: '10+', value: '11_999' },
    ],
    enable: true,
  },
  {
    id: 'created_at',
    name: 'Created At',
    slug: 'created_at',
    type: 'date',
    options: [],
    enable: true,
  },
];

export const ENTRIES_FILTER_LIST: FilterList[] = [
  {
    id: 'completed_at',
    name: 'Entry Date',
    slug: 'completed_at',
    type: 'date',
    options: [],
    enable: true,
  },
  {
    id: 'status',
    name: 'Draft Entry',
    slug: 'status',
    type: 'multi',
    options: [
      { label: 'Yes', value: 'draft' },
      { label: 'No', value: 'live,ended' },
    ],
    enable: true,
  },
  {
    id: 'term',
    name: 'Terms And Conditions',
    slug: 'term',
    type: 'multi',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' },
      { label: 'N/A', value: 'null' },
    ],
    enable: false,
  },
  {
    id: 'newsletter',
    name: 'Newsletter',
    slug: 'newsletter',
    type: 'multi',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' },
      { label: 'N/A', value: 'null' },
    ],
    enable: false,
  },
  {
    id: 'visibility',
    name: 'Entry Method',
    slug: 'visibility',
    type: 'multi',
    options: [
      { label: 'Public', value: 'public' },
      { label: 'Private', value: 'private' },
    ],
    enable: true,
  },
  {
    id: 'segment_label',
    name: 'Reward Label',
    slug: 'segment_label',
    type: 'multi',
    options: [],
    enable: false,
    disabled: true,
  },
  {
    id: 'outcome_title',
    name: 'Result Label',
    slug: 'outcome_title',
    type: 'multi',
    options: [],
    enable: false,
    disabled: true,
  },
  {
    id: 'created_at',
    name: 'Opened Date',
    slug: 'created_at',
    type: 'date',
    options: [],
    enable: true,
  },
  {
    id: 'game_type_slug',
    name: 'Game Type',
    slug: 'game_type_slug',
    type: 'multi',
    options: [],
    enable: true,
  },
];
