const getDateRange = (days = 30): string => {
  const today = new Date();
  const endDate = today.toISOString().split('T')[0];
  const startDate = new Date(today);
  startDate.setDate(startDate.getDate() - days);
  const startDateString = startDate.toISOString().split('T')[0];

  return `${startDateString}_${endDate}`;
};

export default getDateRange;
