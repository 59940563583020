/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import Alert from '../../../../../../componentsV2/DesignSystem/Alert';
import { Avatar } from '../../../../../../componentsV2/DesignSystem/Avatar';
import Drawer from '../../../../../../componentsV2/DesignSystem/Drawer';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import Modal from '../../../../../../componentsV2/DesignSystem/Modal';
import Pills from '../../../../../../componentsV2/DesignSystem/Pills';
import { PillsColors } from '../../../../../../componentsV2/DesignSystem/Pills/interface';
import Skeleton from '../../../../../../componentsV2/DesignSystem/Skeleton';
import { Tags } from '../../../../../../componentsV2/DesignSystem/Tags';
import { TagsColor } from '../../../../../../componentsV2/DesignSystem/Tags/interface';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { FormatDateDayLongMonthYear } from '../../../../../../componentsV2/DesignSystem/Utils/Format';
import TableColumnsControl, {
  buildTableResult,
  filterTableColumns,
} from '../../../../../../componentsV2/TableColumnsControl';
import {
  TableDataCellSerialized,
  TableHeaderElement,
  TypeColumn,
} from '../../../../../../componentsV2/TableColumnsControl/interface';
import useAccount from '../../../../../../hooks/useAccount';
import useQueryParams from '../../../../../../hooks/useQueryParams';
import useQueryString from '../../../../../../hooks/useQueryString';
import useToast from '../../../../../../hooks/useToast';
import { FieldData } from '../../../../../../ts';
import { DELETE_ENTRY, ENTRY_VIEW_MORE } from '../../entries.gql';
import { Entries } from '../../useFetchEntries';
import { useFetchEntriesFields } from '../../useFetchEntriesFields';
import { DEFAULT_COLUMNS } from './DefaultColumns';
import { TableContentPros, ViewMoreEntries } from './interface';
import styles from './styles.module.scss';

export default function TableContent({ data, dataLoading, refetch, setExportFields }: TableContentPros): JSX.Element {
  const [serializedTable, setSerializedTable] = useState<TableHeaderElement[]>();
  const { getAccount, listFields, getDataFields } = useAccount();

  // Load Fields
  useEffect(() => getDataFields(), []);

  const [fetch, { loading: viewMoreDataLoading }] = useLazyQuery<{
    entryViewMore: ViewMoreEntries;
  }>(ENTRY_VIEW_MORE, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ entryViewMore }) => {
      const listData = { ...entryViewMore };
      ['id', 'entry_id', 'first_name', 'last_name', 'email', 'avatar_image', '__typename'].forEach(
        (each) => delete listData[each],
      );
      const userSerialized: ViewMoreEntries = {
        entry_id: entryViewMore?.entry_id,
        first_name: entryViewMore?.first_name ?? '-',
        last_name: entryViewMore?.last_name ?? '-',
        email: entryViewMore?.email ?? '--',
        avatar_image: entryViewMore?.avatar_image ?? null,
        invite_code: entryViewMore?.invite_code,
        listData: Object.entries(listData)
          ?.filter((each) => each[1] != null || each[1] != undefined)
          ?.map((each) => {
            let type;
            let value = String(each[1]).replace(/['"]+/g, '');
            const validateBoolean = ['na', 'true', 'false', '1', '0'].some((each) => each == value);
            const checkTypeof = typeof value;
            if (checkTypeof == 'object') {
              type = 'tag';
              value = JSON.stringify(value);
            } else if (checkTypeof == 'boolean' || validateBoolean) {
              type = 'pill';
              value = value;
            } else if (value.length > 10 && !!new Date(value).getDate()) type = 'date';
            else type = 'text';
            if (each[0] == 'status_promo')
              return {
                label: each[0].split('_')[0],
                value: value,
                type: type,
              };
            return {
              label: each[0].split('_').join(' '),
              value: value,
              type: type,
            };
          }),
      };
      setUserData({ ...userSerialized });
    },
    onError: () => {
      setShowDrawer(false);
      toast('error', 'Error to load more details');
    },
  });

  const { data: fieldsData, loading: fieldsDataLoading } = useFetchEntriesFields(getAccount.accountId);
  const [, setEntriesSelected] = useState<string[]>([]);
  const [userData, setUserData] = useState<ViewMoreEntries>({} as ViewMoreEntries);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const { toast } = useToast();

  const query = useQueryString();

  function closeDrawer() {
    setShowDrawer(false);
  }

  const addQueryParam = useQueryParams();

  const handleCheckboxSelected = (itemsSelected: string[]) => {
    setEntriesSelected(itemsSelected);
  };

  const toggleColumns = (data?: TableHeaderElement[], columns?: string) => {
    setSerializedTable(data);
    addQueryParam({ columns });
  };

  const getSerealized = (dataEntries: Entries[], fields: FieldData[]) => {
    return dataEntries.map((entry) => {
      const serealized = {
        id: entry.entry_id,
        name: entry?.name,
        entry_id: entry?.entry_id,
        promotion_name:
          JSON.stringify({
            tag: [{ color: TagsColor.gray, children: entry.promotion_name }],
          }) ?? null,
        created_at: entry.created_at,
        outcome_title: entry.outcome_title,
        segment_label: entry.segment_label,
        game_type_title: entry.game_type_title,
        email: entry.fields?.['email'] || '',
        completed_at: entry.completed_at,
        status_promo:
          entry.status_promo == 'draft'
            ? JSON.stringify({ pill: { firstIcon: { children: 'edit_square' }, color: PillsColors.yellow } })
            : JSON.stringify({ pill: { firstIcon: { children: 'smart_display' }, color: PillsColors.gray } }),
        visibility:
          entry.visibility == 'private'
            ? JSON.stringify({ pill: { children: 'Private', color: PillsColors.primary } })
            : JSON.stringify({ pill: { children: 'Public', color: PillsColors.green } }),
        newsletter:
          entry.newsletter == 'na'
            ? JSON.stringify({ pill: { children: 'N/A', color: PillsColors.primary } })
            : Boolean(entry.newsletter)
            ? JSON.stringify({ pill: { firstIcon: { children: 'check' }, color: PillsColors.green } })
            : JSON.stringify({ pill: { firstIcon: { children: 'close' }, color: PillsColors.red } }),
        term:
          entry.terms == 'na'
            ? JSON.stringify({ pill: { children: 'N/A', color: PillsColors.primary } })
            : Boolean(entry.terms)
            ? JSON.stringify({ pill: { firstIcon: { children: 'check' }, color: PillsColors.green } })
            : JSON.stringify({ pill: { firstIcon: { children: 'close' }, color: PillsColors.red } }),
        invite_code: entry.invite_code,
      } as TableDataCellSerialized;
      fields.forEach((field) => {
        if (field.base_field !== 'email') {
          serealized[field.base_field] = entry.fields?.[field.base_field] || '';
        }
      });
      return serealized;
    });
  };

  async function getEachData(id: number) {
    fetch({ variables: { accountId: Number(getAccount.accountId), entryId: id } });
    setShowDrawer(true);
  }

  const [deleteEntry, { loading: deleteEntryLoading }] = useMutation(DELETE_ENTRY, {
    onCompleted: () => {
      setShowDeleteModal(false);
      setShowDrawer(false);
      setShowBulkDeleteModal(false);
      setDeleteData('');
      toast('success', 'Entry deleted.');
      refetch({ accountId: getAccount.accountId });
    },
    onError: () => {
      setShowDeleteModal(false);
      setShowDrawer(false);
      setShowBulkDeleteModal(false);
      setDeleteData('');
      toast('error', 'Error to delete entry.');
    },
  });

  const handleRenderList = (type: string, value: string | boolean) => {
    switch (type) {
      case 'tag':
        return (
          typeof value == 'string' &&
          value?.split(',').map((eachTag, index) => <Tags key={`tags-${index}`}>{eachTag}</Tags>)
        );
      case 'pill':
        return !!value ? (
          value === 'na' ? (
            <Pills size="lg" color={PillsColors.primary}>
              N/A
            </Pills>
          ) : Boolean(value) || value == 'true' ? (
            <Pills size="lg" color={PillsColors.green} firstIcon={{ children: 'check' }}></Pills>
          ) : (
            <Pills size="lg" color={PillsColors.gray} firstIcon={{ children: 'close' }}></Pills>
          )
        ) : (
          <></>
        );
      case 'date':
        if (typeof value == 'string') {
          const parseDate = new Date(value.replace(/^"|"$/g, ''));
          return (
            <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
              {typeof value == 'string' && !!parseDate && FormatDateDayLongMonthYear(parseDate)}
            </Paragraph>
          );
        } else return <></>;
      default:
        return (
          <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
            {value}
          </Paragraph>
        );
    }
  };

  // Merge columns
  const mergeColumns = (defaultColumns: TableHeaderElement[], fields: TableHeaderElement[]) => {
    const excludeFields = ['terms', 'newsletter', 'email'];
    const disabledFields = ['first_name', 'last_name'];
    const newFields = fields.filter((each) => !excludeFields.includes(each.base_field));
    const additionalColumns = newFields.map((field, index) => {
      return {
        id: field.base_field,
        base_field: field.base_field,
        type: 'text' as TypeColumn,
        enabled: !disabledFields.includes(field.base_field),
        name: field.name,
        order: index + DEFAULT_COLUMNS.length + 1,
        sortDisabled: true,
      };
    });
    return [...defaultColumns, ...additionalColumns];
  };

  function handleBulkDelete(ids: string) {
    deleteEntry({ variables: { accountId: getAccount.accountId, entryId: ids } });
  }

  useEffect(() => {
    if (!dataLoading && !fieldsDataLoading && listFields) {
      const dataSerealized = getSerealized(data, listFields);

      const columnSeralized = mergeColumns(DEFAULT_COLUMNS, fieldsData).map((each, index) => {
        return {
          ...each,
          id: each.id,
          order: each.base_field == 'name' ? 1 : index + 2,
          type: each.base_field == 'name' ? 'name' : each.type,
        };
      });

      let result: TableHeaderElement[] = buildTableResult(
        columnSeralized.sort((a, b) => a.order - b.order),
        dataSerealized,
      );
      result = filterTableColumns(result, query.get('columns') ?? undefined);
      setSerializedTable(result);
      setExportFields(
        mergeColumns(DEFAULT_COLUMNS, fieldsData)
          .filter((each) => !!each.enabled)
          .map((each) => each.base_field)
          .join(','),
      );
    }
  }, [data, dataLoading, fieldsData, fieldsDataLoading, listFields]);

  return (
    <TableColumnsControl
      firstColumnFixed
      filtersFixed
      column={serializedTable}
      toggleColumns={toggleColumns}
      loading={dataLoading}
      selectLine={{
        handleCheckboxSelected,
        columnId: 'created_at',
      }}
      bulkActions={{
        delete: handleBulkDelete,
        deleteLoading: deleteEntryLoading,
        showDeleteModal: showBulkDeleteModal,
        setShowDeleteModal: setShowBulkDeleteModal,
      }}
      viewMore={{
        callback: getEachData,
        data: userData,
        component: (
          <>
            <Drawer
              showDrawer={showDrawer}
              icon={{ children: 'person' }}
              title="Entry Details"
              callback={closeDrawer}
              footer={[
                {
                  children: 'Delete Entry',
                  variant: 'secondary',
                  size: 'md',
                  firstIcon: { children: 'delete', color: 'red-block' },
                  onClick: () => setShowDeleteModal(true),
                },
              ]}
            >
              <>
                {viewMoreDataLoading && userData ? (
                  <div className={styles['wrapper-entries-details']}>
                    <header>
                      <aside>
                        <Skeleton width="3.75rem" rounded={'50%'} height={3.75} />
                      </aside>
                      <div>
                        <Skeleton width="60%" height={1} />
                        <Skeleton width="40%" height={1} />
                      </div>
                    </header>
                    <main>
                      <ul>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="10%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="20%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="25%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="35%" height={1} />
                        </li>
                      </ul>
                    </main>
                  </div>
                ) : (
                  <div className={styles['wrapper-entries-details']}>
                    <header>
                      <aside>
                        <Avatar
                          name={
                            userData?.first_name && userData?.last_name
                              ? `${userData?.first_name} ${userData?.last_name}`
                              : '-'
                          }
                          size="xl"
                          image={userData?.avatar_image}
                        />
                      </aside>
                      <div>
                        <Paragraph size={1} weight={FontWeight.extraBold} color="gray-space" transform="capitalize">
                          {userData?.first_name && userData?.last_name
                            ? `${userData?.first_name} ${userData?.last_name}`
                            : '-'}
                        </Paragraph>
                        <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
                          {userData?.email ?? '-'}
                        </Paragraph>
                      </div>
                    </header>
                    <main>
                      <ul>
                        {userData?.listData?.map((each, index) => (
                          <li key={`list-data-${index}`}>
                            <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                              {each.label}
                            </Paragraph>
                            {handleRenderList(each.type, each.value)}
                          </li>
                        ))}
                      </ul>
                    </main>
                  </div>
                )}
              </>
            </Drawer>
            {showDeleteModal && userData && (
              <Modal
                title="Really Delete"
                size="small"
                callback={() => {
                  setShowDeleteModal(false);
                  setDeleteData('');
                }}
                leftButton={{ children: 'Cancel', variant: 'secondary' }}
                rightButton={{
                  children: 'Delete',
                  variant: 'red-block',
                  loading: deleteEntryLoading,
                  disabled: deleteEntryLoading || !(`DELETE 1` == deleteData),
                  onClick: () =>
                    deleteEntry({ variables: { accountId: getAccount.accountId, entryId: userData.entry_id } }),
                }}
              >
                <div className={styles['wrapper-modal']}>
                  Are you sure you want to delete?
                  <Alert color="danger-light">This data will be anonymized</Alert>
                  <Input
                    label={`Type DELETE 1 to confirm`}
                    placeholder={`DELETE 1`}
                    value={deleteData}
                    onChange={(e) => setDeleteData(e.target.value.toLocaleUpperCase())}
                  />
                </div>
              </Modal>
            )}
          </>
        ),
      }}
    />
  );
}
