import { Fragment, useEffect, useState } from 'react';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import { InputDate } from '../../../../componentsV2/DesignSystem/Inputs/InputDate';
import { OptionsProps } from '../../../../componentsV2/DesignSystem/Inputs/Selects/interface';
import Select from '../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import SelectMulti from '../../../../componentsV2/DesignSystem/Inputs/Selects/SelectMulti';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import useQueryParams from '../../../../hooks/useQueryParams';
import useQueryString from '../../../../hooks/useQueryString';
import { DropdownSelectFilters } from './DropdownSelectFilters';
import { DropdownSelectFiltersSaved } from './DropdownSelectFiltersSaved';
import { FilterList, FiltersPramsProps, FiltersProps, FilterValueProps, SavedFilterList } from './interface';
import SAVED_FILTER_LIST from './SavedFilterList';
import styles from './styles.module.scss';

export default function Filters({
  fetchLoading,
  clearUrlFilters,
  data: promotionData,
  loadingData: promotionLoading,
  gameTypeData,
  gameTypeLoading,
  params,
  filterList,
  filterListLoading,
  filterKey,
}: FiltersProps): JSX.Element {
  const [filters, setFilters] = useState<FilterList[]>(filterList);
  const [filtersSaved] = useState<SavedFilterList[]>(SAVED_FILTER_LIST);
  const [filterValue, setFilterValue] = useState<FilterValueProps | null>();
  const [enableFilter, setEnableFilter] = useState<boolean>(false);
  const isFilterEnabled = !!filters.find((filter) => !!filter.enable);
  const addQueryParam = useQueryParams();
  const query = useQueryString();

  const loadParametersFilter = (parameters: FiltersPramsProps) => {
    const loadFilter: FilterValueProps = {};
    if (parameters.promo_id) {
      loadFilter['promo_id'] =
        typeof parameters.promo_id == 'object' ? parameters?.promo_id : parameters.promo_id.split(',');
      setFiltersEnabled('promo_id');
    }
    if (parameters.search) {
      loadFilter['search'] = parameters.search;
      setFiltersEnabled('search');
    }
    if (parameters.number_entries) {
      loadFilter['number_entries'] = parameters.number_entries;
      setFiltersEnabled('number_entries');
    }
    if (parameters.created_at) {
      loadFilter['created_at'] = parameters.created_at;
      setFiltersEnabled('created_at');
    }
    if (parameters.completed_at) {
      loadFilter['completed_at'] = parameters.completed_at;
      setFiltersEnabled('completed_at');
    }
    if (parameters.date) {
      loadFilter['date'] = parameters.date;
      setFiltersEnabled('date');
    }
    if (parameters.status) {
      loadFilter['status'] = parameters.status;
      setFiltersEnabled('status');
    }
    if (parameters.visibility) {
      loadFilter['visibility'] = parameters.visibility;
      setFiltersEnabled('visibility');
    }
    if (parameters.game_type_slug) {
      loadFilter['game_type_slug'] = parameters.game_type_slug;
      setFiltersEnabled('game_type_slug');
    }
    if (parameters.term) {
      loadFilter['term'] = parameters.term;
      setFiltersEnabled('term');
    }
    if (parameters.expired_at) {
      loadFilter['expired_at'] = parameters.expired_at;
      setFiltersEnabled('expired_at');
    }
    if (parameters.created_at) {
      loadFilter['created_at'] = parameters.created_at;
      setFiltersEnabled('created_at');
    }
    if (parameters.newsletter) {
      loadFilter['newsletter'] = parameters.newsletter;
      setFiltersEnabled('newsletter');
    }
    if (parameters.outcome_title) {
      loadFilter['outcome_title'] = parameters.outcome_title;
      setFiltersEnabled('outcome_title');
    }
    if (parameters.segment_label) {
      loadFilter['segment_label'] = parameters.segment_label;
      setFiltersEnabled('segment_label');
    }
    setFilterValue(loadFilter);
    addQueryParam({ ...loadFilter, page: 1 });
    filters.findIndex((filter) => filter.enable) > -1;
  };

  const handleDate = (id: string, date: Date | null | (Date | null)[]) => {
    if (date == null && filterValue) {
      const newFilter = { ...filterValue };
      delete newFilter[id];
      setFilterValue({ ...newFilter });
      return;
    }

    const dateArray = Array.isArray(date) ? date : [date];
    const dateFormated = dateArray.map((each) => {
      return each
        ? `${each.getFullYear()}-${each.getMonth() + 1}-${each.getDate() < 10 ? '0' + each.getDate() : each.getDate()}`
        : null;
    });
    setFilterValue({ ...filterValue, [id]: dateFormated.join('_').replaceAll('/', '-') });
  };

  const setFiltersEnabled = (id: string) => {
    setFilters((prevFilters) => prevFilters.map((filter) => (filter.id == id ? { ...filter, enable: true } : filter)));
  };
  const toggleFilters = (id: string) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => (filter.id == id ? { ...filter, enable: !filter.enable } : filter)),
    );
  };
  const toggleSavedFilters = (idList: string[]) => {
    idList.forEach((id) =>
      setFilters((prevFilters) =>
        prevFilters.map((filter) => (filter.id === id ? { ...filter, enable: !filter.enable } : filter)),
      ),
    );
    setEnableFilter(isFilterEnabled ? false : true);
  };

  const handleFilterValue = (id: string, value: string | string[]) => {
    const newFilterValue = { ...filterValue };

    if (value === '' || (Array.isArray(value) && value.length === 0)) {
      delete newFilterValue[id];
    } else {
      if (value == ' ' || !value.length) newFilterValue[id] = ' ';
      else newFilterValue[id] = value;
    }
    setFilterValue(newFilterValue);
  };

  const handleClearFilter = () => {
    setFilterValue({});
    localStorage.removeItem(filterKey);
    clearUrlFilters();
  };

  const parseDate = (dateString: string | undefined, index: number): Date | null => {
    if (!dateString) {
      return null;
    }
    const dateFilter = dateString.replaceAll('"', '');
    const oneDate = dateFilter.split('_')[index];
    const dateArray = oneDate.split('-');
    const retorno = Date.parse(`${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`)
      ? new Date(`${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`)
      : null;
    return retorno;
  };

  const renderInputs = (filter: FilterList) => {
    switch (filter.type) {
      case 'multi':
        return (
          <SelectMulti
            label={filter.name}
            placeholder={promotionLoading ? 'Loading...' : 'Select'}
            onChange={(value) => handleFilterValue(filter.slug, value)}
            selectValue={
              filterValue && filterValue[filter.slug] ? ([] as string[]).concat(filterValue[filter.slug]) : []
            }
            options={filter.options || []}
            optionsAsPills
            fullWidth
            enableSelectAll
            disabled={filter.disabled}
          />
        );

      case 'date':
        return (
          <div className={styles['date-wrapper']}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              {filter.name}
            </Paragraph>
            <InputDate
              disableFuture
              amountOfYears={5}
              startDate={parseDate(filterValue?.[filter.slug]?.toString(), 0)}
              endDate={parseDate(JSON.stringify(filterValue?.[filter.slug]?.toString()), 1)}
              locale="en"
              isRange
              format="full-date"
              slug={filter.slug}
              callback={handleDate}
            />
          </div>
        );

      default:
        return (
          <Select
            label={filter.name}
            placeholder="Select"
            onChange={(value) => handleFilterValue(filter.id, value.toString())}
            options={filter.options}
            defaultValue={filterValue && filterValue[filter.id] ? filterValue[filter.id].toString() : ''}
          />
        );
    }
  };

  function handleFilter() {
    clearUrlFilters();
    if (!!filterValue && !!Object.keys(filterValue).length) {
      const parseFiltersAndSearch = { ...filterValue, search: query.get('search') || undefined };
      localStorage.setItem(filterKey, JSON.stringify(parseFiltersAndSearch));
      addQueryParam({ ...parseFiltersAndSearch, page: 1 }, true);
    }
  }

  useEffect(() => {
    if (!!filterValue && !!Object.keys(filterValue).filter((each) => !!each).length && !promotionLoading)
      setEnableFilter(true);
  }, [promotionLoading]);

  useEffect(() => {
    const local = localStorage.getItem(filterKey);
    if (local) {
      loadParametersFilter(JSON.parse(local));
    } else {
      loadParametersFilter(params);
    }
  }, []);

  useEffect(() => {
    if (promotionData && promotionData.length > 0 && !promotionLoading) {
      const promotionSerialized = promotionData.map((promotion) => ({
        label: promotion.name,
        value: promotion.id,
      })) as OptionsProps[];
      setFilters((prevFilters) => {
        return prevFilters.map((filter) =>
          filter.id === 'promo_id' ? { ...filter, options: promotionSerialized } : filter,
        );
      });
    }
  }, [promotionData, promotionLoading, filterListLoading]);

  useEffect(() => {
    if (gameTypeData && gameTypeData.length > 0 && !gameTypeLoading) {
      const gameTypeSerialized = gameTypeData.map((game) => ({
        label: game.title,
        value: game.slug,
      })) as OptionsProps[];
      setFilters((prevFilters) => {
        return prevFilters.map((filter) =>
          filter.id === 'game_type_slug' ? { ...filter, options: gameTypeSerialized } : filter,
        );
      });
    }
  }, [gameTypeData, gameTypeLoading, filterListLoading]);

  return (
    <>
      <div className={styles['filters-container']}>
        <div className={styles['filters-container-header']}>
          <DropdownSelectFiltersSaved filters={filtersSaved} toggleFilters={toggleSavedFilters} />
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setEnableFilter((prev) => !prev)}
            firstIcon={{ children: 'filter_list' }}
            lastIcon={{ children: enableFilter ? 'expand_less' : 'expand_more', color: 'gray-56' }}
            disabled={filterListLoading && promotionLoading}
            active={enableFilter}
          >
            Filters
          </Button>
        </div>

        {enableFilter && (
          <div className={styles['filters-container-fields']}>
            {isFilterEnabled && (
              <div>
                {filters
                  .filter((filter) => filter.enable === true)
                  .map((filter) => {
                    return <Fragment key={`select-multi-${filter.id}`}>{renderInputs(filter)}</Fragment>;
                  })}
              </div>
            )}
            <footer>
              <div>
                <DropdownSelectFilters filters={filters} toggleFilters={toggleFilters} />
                <Button
                  variant="secondary"
                  size="sm"
                  {...(!!isFilterEnabled && { onClick: handleClearFilter })}
                  disabled={!isFilterEnabled || fetchLoading}
                  loading={fetchLoading}
                >
                  Clear Filter
                </Button>
              </div>
              <div className={styles['wrapper-save-filter']}>
                {/* @todo feature to save filters
                <Input
                  placeholder="Type Filter Name"
                  inputSize="sm"
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  disabled={!filterName}
                />
                <Button
                  variant="secondary"
                  onClick={() => undefined}
                  size="sm"
                  disabled={true}
                  //todo feature to save filters disabled={!filterName}
                  onMouseEnter={() => setShowCreateFilterPopover(true)}
                  onMouseLeave={() => setShowCreateFilterPopover(false)}
                  popover={{
                    color: 'dark',
                    placement: 'top-end',
                    showPopover: showCreateFilterPopover,
                    callback: () => undefined,
                    hiddenOverlay: true,
                    children: 'This feature is coming soon.',
                    propRef: null,
                  }}
                >
                  Save Filter
                </Button>*/}
                <Button size="sm" onClick={handleFilter} loading={fetchLoading} disabled={fetchLoading}>
                  Filter
                </Button>
              </div>
            </footer>
          </div>
        )}
      </div>
    </>
  );
}
