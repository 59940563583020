/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import Alert from '../../../../../../componentsV2/DesignSystem/Alert';
import { Avatar } from '../../../../../../componentsV2/DesignSystem/Avatar';
import Drawer from '../../../../../../componentsV2/DesignSystem/Drawer';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import Modal from '../../../../../../componentsV2/DesignSystem/Modal';
import Pills from '../../../../../../componentsV2/DesignSystem/Pills';
import { PillsColors } from '../../../../../../componentsV2/DesignSystem/Pills/interface';
import Skeleton from '../../../../../../componentsV2/DesignSystem/Skeleton';
import { Tags } from '../../../../../../componentsV2/DesignSystem/Tags';
import { TagsColor } from '../../../../../../componentsV2/DesignSystem/Tags/interface';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { FormatDateDayLongMonthYear } from '../../../../../../componentsV2/DesignSystem/Utils/Format';
import TableColumnsControl, {
  buildTableResult,
  filterTableColumns,
} from '../../../../../../componentsV2/TableColumnsControl';
import {
  TableDataCellSerialized,
  TableHeaderElement,
  TypeColumn,
} from '../../../../../../componentsV2/TableColumnsControl/interface';
import useAccount from '../../../../../../hooks/useAccount';
import useQueryParams from '../../../../../../hooks/useQueryParams';
import useQueryString from '../../../../../../hooks/useQueryString';
import useToast from '../../../../../../hooks/useToast';
import { CONTACT_VIEW_MORE, DELETE_CONTACT } from '../../contacts.gql';
import { ContactsProps } from '../../useFetchContacts';
import { useFetchContactsFields } from '../../useFetchContactsFields';
import { DEFAULT_COLUMNS } from './DefaultColumns';
import { TableContentPros, ViewMoreContactsProps } from './interface';
import styles from './styles.module.scss';
import { FieldData } from '../../../../../../ts';

export default function TableContent({ data, dataLoading, setExportFields }: TableContentPros): JSX.Element {
  const { toast } = useToast();
  const { getAccount, listFields } = useAccount();
  const { data: fieldsData, loading: fieldsDataLoading } = useFetchContactsFields(getAccount.accountId);
  const [fetch, { loading: viewMoreDataLoading }] = useLazyQuery<{
    contactViewMore: ViewMoreContactsProps;
  }>(CONTACT_VIEW_MORE, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ contactViewMore }) => {
      const listData = { ...contactViewMore };
      [
        'id',
        'member_id',
        'first_name',
        'last_name',
        'email',
        'avatar_image',
        'promotions_name',
        'promotions_entries',
        '__typename',
      ].forEach((each) => delete listData[each]);
      const userSerialized: ViewMoreContactsProps = {
        member_id: contactViewMore.member_id,
        first_name: contactViewMore.first_name ?? '-',
        last_name: contactViewMore.last_name ?? '-',
        email: contactViewMore.email ?? '-',
        avatar_image: contactViewMore.avatar_image,
        invite_code: contactViewMore.invite_code,
        listData: Object.entries(listData)
          .filter((each) => !!each[1])
          .map((each) => {
            let type;
            const checkTypeof = typeof each[1];
            if (checkTypeof == 'object') type = 'tag';
            else if (checkTypeof == 'boolean') type = 'pill';
            else if (!!Date.parse(String(each[1]))) type = 'date';
            else type = 'text';
            return {
              label:
                each[0].split('_').join(' ') == 'promotions'
                  ? 'Promotions/Outcome Title/Outcome Label/Entries'
                  : each[0].split('_').join(' '),
              value: JSON.stringify(each[1]),
              type: type,
            };
          }),
      };
      setUserData({ ...userSerialized });
    },
    onError: () => {
      console.log('Check error logs');
    },
  });
  const [serializedTable, setSerializedTable] = useState<TableHeaderElement[]>();
  const [, setContactsSelected] = useState<string[]>([]);
  const [userData, setUserData] = useState<ViewMoreContactsProps>({} as ViewMoreContactsProps);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const query = useQueryString();

  const [deleteMember, { loading: deleteMemberLoading }] = useMutation(DELETE_CONTACT, {
    onCompleted: (res) => {
      setShowDeleteModal(false);
      setShowDrawer(false);
      setShowBulkDeleteModal(false);
      setDeleteData('');
      toast('success', res.deleteContact.message);
    },
    onError: () => {
      setShowDeleteModal(false);
      setShowDrawer(false);
      setShowBulkDeleteModal(false);
      setDeleteData('');
      toast('error', 'Error to delete contact.');
      console.log('Check graphql logs.');
    },
  });

  function closeDrawer() {
    setShowDrawer(false);
  }

  const addQueryParam = useQueryParams();

  const toggleColumns = (toggleData?: TableHeaderElement[], columns?: string) => {
    setSerializedTable(toggleData);
    addQueryParam({ columns });
  };

  const handleCheckboxSelected = (itemsSelected: string[]) => {
    setContactsSelected(itemsSelected);
  };

  function getEachData(id: number) {
    fetch({ variables: { accountId: Number(getAccount.accountId), memberId: id } });
    setShowDrawer(true);
  }

  const getSerealized = (dataEntries: ContactsProps[], fields: FieldData[]) => {
    return dataEntries.map((member) => {
      const serealized = {
        id: member.member_id,
        name: member.name.trim(),
        promotions_name:
          JSON.stringify({
            tag: member.promotions.map((each) => ({ color: TagsColor.gray, children: each })),
          }) ?? null,
        email: member.base_data?.['email'] || '',
        total_entries: member.total_entries,
        created_at: member.created_at,
      } as TableDataCellSerialized;
      fields.forEach((field) => {
        if (field.base_field !== 'email') {
          serealized[field.base_field] = member.fields?.[field.base_field] || '';
        }
      });

      return serealized;
    });
  };

  const handleRenderList = (type: string, value: string | boolean) => {
    switch (type) {
      case 'tag':
        return (
          typeof value == 'string' && (
            <div className={styles['promotions-entries-wrapper']}>
              {JSON.parse(value).map(({ data }: any, index: number) => {
                return (
                  <div key={`tags-${index}`}>
                    <Tags>{data.title}</Tags>
                    {data.outcome?.outcome_title && <Tags>{data.outcome.outcome_title}</Tags>}
                    {data.outcome?.segment_label && <Tags>{data.outcome.segment_label}</Tags>}
                    <Tags color={TagsColor.gray}>{data.entries}</Tags>
                  </div>
                );
              })}
            </div>
          )
        );
      case 'pill':
        return !!value ? (
          <Pills size="lg" color={PillsColors.green}>
            Checked
          </Pills>
        ) : (
          <Pills size="lg" color={PillsColors.red}>
            Unhecked
          </Pills>
        );
      case 'date':
        if (typeof value == 'string') {
          const parseDate = new Date(value.replace(/^"|"$/g, ''));
          return (
            <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
              {typeof value == 'string' && !!parseDate && FormatDateDayLongMonthYear(parseDate)}
            </Paragraph>
          );
        } else return <></>;
      default:
        return (
          <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
            {value}
          </Paragraph>
        );
    }
  };

  // Merge columns
  const mergeColumns = (defaultColumns: TableHeaderElement[], fields: TableHeaderElement[]) => {
    const excludeFields = ['email'];
    const disabledFields = ['first_name', 'last_name'];
    const newFields = fields.filter((each) => !excludeFields.includes(each.base_field));
    const additionalColumns = newFields.map((field, index) => {
      return {
        id: field.id,
        base_field: field.base_field,
        type: 'text' as TypeColumn,
        enabled: !disabledFields.includes(field.base_field),
        name: field.name,
        order: index + DEFAULT_COLUMNS.length + 1,
        sortDisabled: true,
      };
    });
    return [...defaultColumns, ...additionalColumns];
  };

  function handleBulkDelete(ids: string) {
    deleteMember({ variables: { accountId: getAccount.accountId, memberId: ids } });
  }

  useEffect(() => {
    if (!dataLoading && !fieldsDataLoading && listFields) {
      const dataSerealized = getSerealized(data, listFields);
      const columnSeralized = mergeColumns(DEFAULT_COLUMNS, fieldsData).map((each, index) => {
        return {
          ...each,
          id: each.id,
          order: each.base_field == 'name' ? 1 : index + 2,
          type: each.base_field == 'name' ? 'name' : each.type,
        };
      });

      let result: TableHeaderElement[] = buildTableResult(
        columnSeralized.sort((a, b) => a.order - b.order),
        dataSerealized,
      );
      result = filterTableColumns(result, query.get('columns') || undefined);
      setSerializedTable(result);
      setExportFields(
        mergeColumns(DEFAULT_COLUMNS, fieldsData)
          .filter((each) => !!each.enabled)
          .map((each) => each.base_field)
          .join(','),
      );
    }
  }, [data, dataLoading, fieldsData, fieldsDataLoading]);

  return (
    <TableColumnsControl
      firstColumnFixed
      filtersFixed
      column={serializedTable}
      toggleColumns={toggleColumns}
      loading={dataLoading}
      selectLine={{
        handleCheckboxSelected,
        columnId: 'created_at',
      }}
      bulkActions={{
        delete: handleBulkDelete,
        deleteLoading: deleteMemberLoading,
        showDeleteModal: showBulkDeleteModal,
        setShowDeleteModal: setShowBulkDeleteModal,
      }}
      viewMore={{
        callback: getEachData,
        data: userData,
        component: (
          <>
            <Drawer
              showDrawer={showDrawer}
              icon={{ children: '01' }}
              title="Contact Details"
              callback={closeDrawer}
              footer={[
                {
                  children: 'Delete Contact',
                  variant: 'secondary',
                  size: 'md',
                  firstIcon: { children: 'delete', color: 'red-block' },
                  onClick: () => setShowDeleteModal(true),
                },
              ]}
            >
              <>
                {viewMoreDataLoading && userData ? (
                  <div className={styles['wrapper-entries-details']}>
                    <header>
                      <aside>
                        <Skeleton width="3.75rem" rounded={'50%'} height={3.75} />
                      </aside>
                      <div>
                        <Skeleton width="60%" height={1} />
                        <Skeleton width="40%" height={1} />
                      </div>
                    </header>
                    <main>
                      <ul>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="10%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="20%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="25%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="35%" height={1} />
                        </li>
                      </ul>
                    </main>
                  </div>
                ) : (
                  <div className={styles['wrapper-entries-details']}>
                    <header>
                      <aside>
                        <Avatar
                          name={
                            userData?.first_name && userData?.last_name
                              ? `${userData?.first_name} ${userData?.last_name}`
                              : '-'
                          }
                          size="xl"
                          image={userData?.avatar_image}
                        />
                      </aside>
                      <div>
                        <Paragraph size={1} weight={FontWeight.extraBold} color="gray-space">
                          {userData?.first_name && userData?.last_name
                            ? `${userData.first_name} ${userData.last_name}`
                            : '-'}
                        </Paragraph>
                        <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
                          {userData.email ?? '-'}
                        </Paragraph>
                      </div>
                    </header>
                    <main>
                      <ul>
                        {userData?.listData?.map((each, index) => (
                          <li key={`list-data-${index}`}>
                            <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                              {each.label}
                            </Paragraph>
                            {handleRenderList(each.type, each.value)}
                          </li>
                        ))}
                      </ul>
                    </main>
                  </div>
                )}
              </>
            </Drawer>
            {showDeleteModal && userData && (
              <Modal
                title="Really Delete"
                size="small"
                callback={() => setShowDeleteModal(false)}
                leftButton={{ children: 'Cancel', variant: 'secondary' }}
                rightButton={{
                  children: 'Delete',
                  variant: 'red-block',
                  disabled: !(`DELETE` == deleteData),
                }}
              >
                <div className={styles['wrapper-modal']}>
                  Are you sure you want to delete?
                  <Alert color="danger-light">These contacts will be anonymized</Alert>
                  <Input
                    label={`Type DELETE ${`${userData.first_name} ${userData.last_name}`.toLocaleUpperCase()} ENTRY to confirm`}
                    placeholder="DELETE"
                    value={deleteData}
                    onChange={(e) => setDeleteData(e.target.value.toLocaleUpperCase())}
                  />
                </div>
              </Modal>
            )}

            {showDeleteModal && userData && (
              <Modal
                title="Really Delete"
                size="small"
                callback={() => setShowDeleteModal(false)}
                leftButton={{ children: 'Cancel', variant: 'secondary' }}
                rightButton={{
                  children: 'Delete',
                  variant: 'red-block',
                  loading: deleteMemberLoading,
                  disabled: deleteMemberLoading || !(`DELETE 1` == deleteData),
                  onClick: () =>
                    deleteMember({
                      variables: { accountId: getAccount.accountId, memberId: String(userData.member_id) },
                    }),
                }}
              >
                <div className={styles['wrapper-modal']}>
                  Are you sure you want to delete?
                  <Alert color="danger-light">This contact will be anonymized</Alert>
                  <Input
                    label={`Type DELETE 1 to confirm`}
                    placeholder={`DELETE 1`}
                    value={deleteData}
                    onChange={(e) => setDeleteData(e.target.value.toLocaleUpperCase())}
                  />
                </div>
              </Modal>
            )}
          </>
        ),
      }}
    />
  );
}
