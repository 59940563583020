import { ReactElement, ReactNode } from 'react';

export interface ParagraphyProps {
  size: 1 | 2 | 3;
  weight?: FontWeight;
  children: ReactNode | ReactElement | ReactElement[];
  color?: string;
  ellipsis?: 1 | 2;
  mb?: 2;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  transform?: 'capitalize' | 'uppercase' | 'lowercase';
}
export interface headersProps {
  children: string;
  weight?: FontWeight;
  color?: Color;
  size: 1 | 2 | 3 | 4 | 5 | 6;
}

export enum Color {
  yellowBee = 'yellowBee',
  gray16 = 'gray-16',
  gray24 = 'gray-24',
  gray40 = 'gray-40',
  gray48 = 'gray-48',
  gray64 = 'gray-64 ',
  graySpace = 'gray-space',
  danger = 'red-block',
  redBlock = 'red-block',
  primary = 'primary ',
}

export enum FontWeight {
  extraBold = 'extra-bold',
  semiBold = 'semi-bold',
  regular = 'regular',
}
