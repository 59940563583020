import Icon from '../../Icon';
import DatePicker from 'react-datepicker';
import styles from './styles.module.scss';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import { InputDateProps } from './interface';
import { addDays, differenceInDays } from 'date-fns';
import { useState } from 'react';

export function InputDate({
  date,
  callback,
  isRange,
  format,
  locale,
  slug,
  startDate,
  endDate,
  disableFuture,
  amountOfYears,
}: InputDateProps): JSX.Element {
  const [rangeDates, setRangeDates] = useState<string[]>([] as string[]);
  function chooseFormat(dateFormat: string) {
    switch (dateFormat) {
      case 'year':
        return { showYearPicker: true };
        break;
      case 'month-year':
        return { showMonthYearPicker: true };
      default:
        return { showFullMonthYearPicker: true };
    }
  }
  return (
    <div className={styles['input-date']}>
      <DatePicker
        placeholderText="Select date"
        {...(disableFuture && { maxDate: addDays(new Date(), 0) })}
        {...(amountOfYears && { yearDropdownItemNumber: amountOfYears })}
        showYearDropdown
        scrollableYearDropdown
        {...(isRange
          ? {
              selected: startDate,
              startDate: startDate,
              endDate: endDate,
              selectsRange: true,
              onChange: (eachDate) => callback(slug, eachDate),
            }
          : { selectsRange: false, selected: date, onChange: (eachDate) => callback(slug, eachDate) })}
        {...(!!format && chooseFormat(format))}
        showIcon
        icon={
          startDate || endDate ? (
            <Icon
              onClick={() => {
                callback(slug, null), setRangeDates([]);
              }}
              style={{ cursor: 'pointer' }}
            >
              close
            </Icon>
          ) : (
            <Icon>calendar_month</Icon>
          )
        }
        calendarClassName={styles['calendar-wrapper']}
        locale={locale}
        monthClassName={(eachDay) => {
          const isSelectedDay =
            eachDay.getDate() == startDate?.getDate() &&
            eachDay.getMonth() == startDate?.getMonth() &&
            eachDay.getFullYear() == startDate?.getFullYear();

          return styleModuleClasses(styles, 'day', {
            selected: !!isSelectedDay,
          });
        }}
        onDayMouseEnter={(eachDay) => {
          const isOnRange = startDate && eachDay > startDate;
          if (startDate && isOnRange) {
            const amountDifferenceDays =
              differenceInDays(eachDay, startDate) > 2
                ? differenceInDays(eachDay, startDate) - 1
                : differenceInDays(eachDay, startDate);
            let hoverDates = [] as string[];
            if (amountDifferenceDays > 1) {
              for (let i = 1; i <= amountDifferenceDays; i++) {
                hoverDates = [...hoverDates, addDays(startDate, i).toISOString()];
              }
            }

            return setRangeDates(hoverDates);
          } else return setRangeDates([]);
        }}
        dayClassName={(eachDay) => {
          const isToday =
            eachDay.getDate() == new Date().getDate() &&
            eachDay.getMonth() == new Date().getMonth() &&
            eachDay.getFullYear() == new Date().getFullYear();
          const isSelectedStartDay =
            eachDay.getDate() == startDate?.getDate() &&
            eachDay.getMonth() == startDate?.getMonth() &&
            eachDay.getFullYear() == startDate?.getFullYear();
          const isSelectedEndDay =
            eachDay.getDate() == endDate?.getDate() &&
            eachDay.getMonth() == endDate?.getMonth() &&
            eachDay.getFullYear() == endDate?.getFullYear();
          const isOnRange =
            rangeDates.includes(eachDay.toISOString()) ||
            (!!startDate && !!endDate && eachDay > startDate && eachDay < endDate);
          const isDisabled = !disableFuture ? false : eachDay > new Date();

          return styleModuleClasses(styles, 'day', {
            ['is-today']: isToday && !isSelectedStartDay && !isOnRange,
            ['is-on-range']: isOnRange,
            selected: (!!isSelectedStartDay || !!isSelectedEndDay) && !isOnRange,
            ...(disableFuture && { ['disabled']: isDisabled }),
          });
        }}
      />
    </div>
  );
}
