import { useState } from 'react';
import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../../componentsV2/DesignSystem/Sections/interface';
import { Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import ShowCode from '../../../../Campaign/Content/Share/PublicMode/ShowCode';
import useShareLinks from '../../ShareLinksProvider';
import styles from './styles.module.scss';
import ShowPopupCode from '../../../../Campaign/Content/Share/PublicMode/ShowPopupCode';

export default function EmbedPage(): JSX.Element {
  const [showStandardCode, setShowStandardCode] = useState<boolean>(false);
  const [showFullPageCode, setShowFullPageCode] = useState<boolean>(false);
  const [showPopupCode, setShowPopupCode] = useState<boolean>(false);
  const [showSliderCode, setShowSliderCode] = useState<boolean>(false);

  const { promotionSelected } = useShareLinks();

  const toggleStandardCode = () => setShowStandardCode((showCode) => !showCode);
  const toggleFullpageCode = () => setShowFullPageCode((showCode) => !showCode);
  const togglePopupCode = () => setShowPopupCode((showCode) => !showCode);
  const toggleSliderCode = () => setShowSliderCode((showCode) => !showCode);

  return (
    <SectionCollapsibleFull
      badge={{
        status: StatusBadge.icon,
        icon: { name: 'add_to_queue', color: 'enabled' },
        number: 2,
      }}
      expanded={true}
      header={{ title: 'Embed Page' }}
      containerClassname={styles['container-section']}
    >
      <>
        <Paragraph size={2}>Choose Embed options to add your promotion to your website</Paragraph>
        <div className={styles['embed-list']}>
          <div className={styles['card']} onClick={toggleStandardCode}>
            <img
              src="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/standard-iframe.png"
              alt="Our logo image"
              width={250}
            />
            <Paragraph size={3}>Standard</Paragraph>
          </div>
          <div className={styles['card']} onClick={toggleFullpageCode}>
            <img
              src="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/fullpage-iframe.png"
              alt="Our logo image"
              width={250}
            />
            <Paragraph size={3}>Full Page</Paragraph>
          </div>
          <div className={styles['card']} onClick={togglePopupCode}>
            <img
              src="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/modal-iframe.png"
              alt="Our logo image"
              width={250}
            />
            <Paragraph size={3}>Popup</Paragraph>
          </div>
          <div className={styles['card']} onClick={toggleSliderCode}>
            <img
              src="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/modal-iframe.png"
              alt="Our logo image"
              width={250}
            />
            <Paragraph size={3}>Slider</Paragraph>
          </div>
        </div>
        {showStandardCode && (
          <ShowCode
            toggleModal={toggleStandardCode}
            promote={{
              id: promotionSelected.id,
              external_domain: promotionSelected.externalDomain,
              render: promotionSelected.render,
            }}
            micrositeUrl={promotionSelected.micrositeUrl}
          />
        )}
        {showFullPageCode && (
          <ShowPopupCode
            toggleModal={toggleFullpageCode}
            promote={{
              id: promotionSelected.id,
              external_domain: promotionSelected.externalDomain,
              render: promotionSelected.render,
            }}
            isFullScreen
            micrositeUrl={promotionSelected.micrositeUrl}
          />
        )}
        {showPopupCode && (
          <ShowPopupCode
            toggleModal={togglePopupCode}
            promote={{
              id: promotionSelected.id,
              external_domain: promotionSelected.externalDomain,
              render: promotionSelected.render,
            }}
            micrositeUrl={promotionSelected.micrositeUrl}
          />
        )}
        {showSliderCode && (
          <ShowPopupCode
            toggleModal={toggleSliderCode}
            promote={{
              id: promotionSelected.id,
              external_domain: promotionSelected.externalDomain,
              render: promotionSelected.render,
            }}
            isPositionAllowed
            micrositeUrl={promotionSelected.micrositeUrl}
          />
        )}
      </>
    </SectionCollapsibleFull>
  );
}
