import Dropdown from '../../../../componentsV2/DesignSystem/Dropdown';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import styles from './styles.module.scss';
import { DropdownSelectSavedFiltersProps } from './interface';
import { useState } from 'react';

export function DropdownSelectFiltersSaved({ filters, toggleFilters }: DropdownSelectSavedFiltersProps): JSX.Element {
  const [showSavedPopover, setShowSavedPopover] = useState(false);

  return (
    <Dropdown
      activeButton={{
        button: {
          variant: 'secondary',
          lastIcon: { children: 'expand_more', color: 'gray-56' },
          children: 'Saved Searchs',
          size: 'sm',
          disabled: true,
          onMouseEnter: () => setShowSavedPopover(true),
          onMouseLeave: () => setShowSavedPopover(false),
          popover: {
            color: 'dark',
            placement: 'top-end',
            showPopover: showSavedPopover,
            callback: () => undefined,
            hiddenOverlay: true,
            children: 'This feature is coming soon.',
            propRef: null,
          },
        },
      }}
      size="xs"
      placement="bottom-start"
    >
      <div className={styles['wrapper-manage-filters']}>
        {filters.map((filter) => (
          <Button
            fullWidth
            variant="tertiary"
            text={{ color: 'dark', align: 'between' }}
            key={filter.id}
            onClick={() => toggleFilters(filter.options.map((each) => String(each.value)))}
          >
            <Paragraph size={2} weight={FontWeight.semiBold}>
              {filter.name}
            </Paragraph>
          </Button>
        ))}
      </div>
    </Dropdown>
  );
}
