import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../../components/Pagination/paginator.gql';

export const ENTRY_VIEW_MORE = gql`
  query entryViewMore($accountId: ID!, $entryId: ID!) {
    entryViewMore(accountId: $accountId, entryId: $entryId) {
      entry_id
      first_name
      last_name
      email
      name
      entry_id
      promo_id
      created_at
      completed_at
      promotion_name
      visibility
      status_promo
      game_type_title
      game_type_slug
      invite_code
      reward_id
      reward_label
      result_id
      result_label
      terms
      newsletter
    }
  }
`;

export const ENTRIES = gql`
  query entries($accountId: ID!, $pagination: PaginationInput, $filters: AudienceFilters) {
    entries(accountId: $accountId, pagination: $pagination, filters: $filters) {
      data {
        name
        entry_id
        promo_id
        outcome_id
        created_at
        completed_at
        fields
        promotion_name
        visibility
        status_promo
        game_type_title
        game_type_slug
        invite_code
        outcome_title
        segment_label
        segment_id
        terms
        newsletter
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const ENTRIES_FIELDS = gql`
  query entriesFields($accountId: ID!) {
    entriesFields(accountId: $accountId) {
      data {
        id
        name
        base_field
        slug
        is_uid_field
        type
        sortDisabled
        width
        enabled
        order
        is_optin
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const DELETE_ENTRY = gql`
  mutation deleteEntry($accountId: ID!, $entryId: ID!) {
    deleteEntry(accountId: $accountId, entryId: $entryId) {
      status
      message
    }
  }
`;

export const CAMPAIGNS = gql`
  query campaigns($accountId: ID!, $page: Int, $pageLimit: Int) {
    campaigns(accountId: $accountId, page: $page, pageLimit: $pageLimit) {
      data {
        id
        title
        slug
        description
        plan_name
        disabled
        image
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;
