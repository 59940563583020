import { useEffect } from 'react';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import Icon from '../Icon';
import { Button } from '../Inputs/Buttons/ButtonAction';
import Overlay from '../Overlay';
import { Heading } from '../Typography';
import { Color, FontWeight } from '../Typography/interface';
import { DrawerProps } from './interface';
import styles from './styles.module.scss';

export default function Drawer({
  showDrawer,
  breadcrumb,
  title,
  icon,
  callback,
  children,
  footer,
}: DrawerProps): JSX.Element {
  useEffect(() => {
    if (!showDrawer) {
      const body = document.querySelector('body');
      const isThereOverlay = document.querySelectorAll('.selector-overlay');
      if (body && isThereOverlay.length <= 1) body.style.overflowY = '';
    }
  }, [showDrawer]);
  return (
    <>
      {showDrawer && <Overlay color="rgba(0, 0, 0, 0.7)" backgroundBlur="2.5px" callback={callback} />}

      <aside className={styleModuleClasses(styles, 'drawer', { open: showDrawer })}>
        <header className={styleModuleClasses(styles, { 'header-design': !!title })}>
          {!!title && (
            <div>
              {!!breadcrumb && (
                <>
                  <Heading size={4} color={Color.gray48}>
                    {breadcrumb}
                  </Heading>
                  <Heading size={4} color={Color.gray16}>
                    /
                  </Heading>
                </>
              )}
              {!!icon && (
                <div className={styleModuleClasses(styles, 'wrapper-icon', icon.color)}>
                  {!Number(icon.children) ? (
                    <Icon {...icon} />
                  ) : (
                    <Heading size={5} weight={FontWeight.extraBold}>
                      {icon.children}
                    </Heading>
                  )}
                </div>
              )}
              <Heading size={4}>{title}</Heading>
            </div>
          )}
          <Button
            variant="tertiary"
            firstIcon={{ children: 'close' }}
            text={{ color: 'dark' }}
            size="lg"
            onClick={callback}
          />
        </header>
        <main className={styleModuleClasses(styles, { 'main-design': !!title })}>{children}</main>
        {!!footer && (
          <footer>
            {footer.map((button, index) => {
              if (!Object.keys(button).length) return;
              return <Button key={index} {...button} />;
            })}
          </footer>
        )}
      </aside>
    </>
  );
}
