import { useEffect, useState } from 'react';
import Pagination from '../../../../componentsV2/Pagination';
import useAccount from '../../../../hooks/useAccount';
import useQueryParams from '../../../../hooks/useQueryParams';
import useQueryString from '../../../../hooks/useQueryString';
import { SendEmailProvider } from '../../ShareCenter/SendEmail/SendEmailProvider';
import Filters from './Filters';
import NoInviteLinks from './NoInviteLinks';
import PreviewEmailModal from './PreviewEmailModal';
import SendEmailModal from './SendEmailModal';
import { UrlFilters } from './interface';
import styles from './styles.module.scss';
import { useFetchInviteLinksByAccount } from './useFetchInviteLinksByAccount';
import TableContent from './TableContent';
import Header from '../Header';

export default function ManageInviteLinks(): JSX.Element {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [messageIdPreview, setMessageIdPreview] = useState<number | undefined>();
  const [modalData] = useState<{ code: string; invitelinkUrl: string; firstName?: string }>();
  const [exportCsv, setExportCsv] = useState<string[][]>([]);
  const [exportSelectedCsv, setSelectedExportCsv] = useState<string[][]>([]);

  const addQueryParam = useQueryParams();

  const { getAccount, listFields, getDataFields } = useAccount();
  // Load Fields
  useEffect(() => getDataFields(), []);

  // Filters
  const query = useQueryString();
  const queryValues = {
    currentPage: Number(query.get('page')) || 1,
    order: query.get('order') || 'created_at',
    sort: query.get('sort') || 'desc',
    tableColumns: query.get('columns') || undefined,
  };

  const filters = {
    code: query.get('code') || undefined,
    entry_search: query.get('entry_search') || undefined,
    user_id: query.get('user_id') || undefined,
    promotion_id: query.get('promotion_id') || undefined,
    created_at: query.get('created_at') || undefined,
    invite_type: query.get('invite_type') || undefined,
    invite_used: query.get('invite_used') || undefined,
    generator_id: query.get('generator_id') || undefined,
  };

  const params: UrlFilters = {
    order: queryValues.order,
    sort: queryValues.sort,
    ...filters,
  };

  // Query Fetch InviteLinks
  const {
    data: dataInviteLink,
    loading: loadingInviteLink,
    pagination,
    fetchInviteLinks,
  } = useFetchInviteLinksByAccount(getAccount.accountId, queryValues.currentPage, params);

  const handlePagination = (page: number) => {
    addQueryParam({ page });
  };

  const searchInviteLinks = (field: string, value: string) => {
    const searchFilter = value ? { [field]: value } : { [field]: '' };

    const filters = {
      order: params.order,
      sort: params.sort,
      ...searchFilter,
    };

    addQueryParam({ ...filters, page: 1 });
  };

  const clearUrlFilters = () => {
    addQueryParam({
      user_id: undefined,
      promotion_id: undefined,
      created_at: undefined,
      invite_type: undefined,
      invite_used: undefined,
      generator_id: undefined,
      page: 1,
    });
  };

  const searchEntries = (field: string, value: string) => {
    const searchFilter = value ? { [field]: value } : { [field]: '' };
    const filters = {
      ...searchFilter,
    };
    addQueryParam({ ...filters, page: 1 });
  };

  return (
    <>
      <section className={styles['wrapper']}>
        <section className={styles['wrapper-result']}>
          <Filters search={searchInviteLinks} clearUrlFilters={clearUrlFilters} params={params} />
          {dataInviteLink?.length === 0 &&
          Object.values(filters).some((value) => value === undefined) &&
          !loadingInviteLink ? (
            <NoInviteLinks />
          ) : (
            <div className={styles['content-container']}>
              <Header
                title="All InviteLinks"
                exportCsv={exportCsv}
                search={searchEntries}
                // @todo v3
                // pills={[
                //   { children: '10', color: PillsColors.green, firstIcon: { children: 'mark_email_read', size: 'md' } },
                //   { children: '20', color: PillsColors.yellow, firstIcon: { children: 'schedule_send', size: 'md' } },
                //   { children: '5', color: PillsColors.red, firstIcon: { children: 'mark_email_unread', size: 'md' } },
                // ]}
              />
              <TableContent
                data={dataInviteLink}
                listFields={listFields}
                dataLoading={loadingInviteLink}
                exportSelectedCsv={exportSelectedCsv}
                setExportSelectedCsv={setSelectedExportCsv}
                setExportCsv={setExportCsv}
                fetchInviteLinks={fetchInviteLinks}
              />
              <footer style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <Pagination
                  total={pagination.total}
                  currentPage={queryValues.currentPage}
                  totalPerPage={pagination.totalPerPage}
                  setPage={handlePagination}
                />
              </footer>
            </div>
          )}
        </section>
      </section>
      {openModal && modalData?.code && (
        <SendEmailProvider>
          <SendEmailModal
            setOpenModal={setOpenModal}
            code={modalData.code}
            invitelinkUrl={modalData?.invitelinkUrl}
            firstName={modalData?.firstName}
          />
        </SendEmailProvider>
      )}

      {messageIdPreview && <PreviewEmailModal setMessageIdPreview={setMessageIdPreview} messageId={messageIdPreview} />}
    </>
  );
}
