import { ApolloError, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PaginationInput, SimplePaginationInfo, paginatorInfo } from '../../../../ts';
import { CONTACTS } from './contacts.gql';

export interface ContactsFilters {
  sort_order?: string;
  sort_by?: string;
  promo_id?: string;
  search?: string;
}

export interface ContactsVariables {
  sort: 'asc' | 'desc';
  key: string;
  pagination?: PaginationInput;
}

export interface ContactsProps {
  name: string;
  account_id: number;
  uid: string;
  uid_field_id: number;
  referred_count: string;
  referred_entry_count: string;
  created_at: string;
  fields: Record<string, string | number>;
  entries_count: string;
  interactions: string;
  member_id: number;
  promo_ids: string;
  promotions: [string];
  terms: string;
  total_entries: number;
  base_data: Record<string, string | number>;
}

interface ContactsResult {
  loading: boolean;
  data: ContactsProps[];
  pagination: SimplePaginationInfo;
  fetchContacts: (variables?: ContactsVariables) => void;
}

export function useFetchContacts(
  accountId: number,
  { page, limit }: PaginationInput,
  filters: ContactsFilters,
): ContactsResult {
  const [pagination, setPagination] = useState<{ total: number; currentPage: number; totalPerPage: number }>({
    total: 0,
    currentPage: 1,
    totalPerPage: 10,
  });

  const { data, loading, refetch } = useQuery<{
    contacts: { data: ContactsProps[]; paginatorInfo: paginatorInfo };
  }>(CONTACTS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      pagination: {
        page,
        limit,
      },
      filters,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const fetchContacts = (variables?: ContactsVariables) => {
    refetch(variables);
  };

  useEffect(() => {
    if (data?.contacts.paginatorInfo) {
      setPagination({
        total: data.contacts.paginatorInfo.total,
        currentPage: data.contacts.paginatorInfo.current_page,
        totalPerPage: Number(data.contacts.paginatorInfo.per_page),
      });
    }
  }, [data?.contacts.paginatorInfo]);

  if (loading) {
    return {
      loading: true,
      data: [],
      pagination,
      fetchContacts,
    };
  }

  return {
    loading: false,
    data: data?.contacts?.data || [],
    pagination,
    fetchContacts,
  };
}
