import { useEffect, useRef, useState } from 'react';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import Popover from '../DesignSystem/Popover';

interface ButtonCopy {
  text: string;
  disabled?: boolean;
  hoverMessage?: string;
  onClickMessage?: string;
}

export default function ButtonCopy({ text, disabled, hoverMessage, onClickMessage }: ButtonCopy): JSX.Element {
  const buttonTooltipCopyRef = useRef<HTMLButtonElement>(null);
  const [showPopoverCopy, setShowPopoverCopy] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  return (
    <>
      <Button
        ref={buttonTooltipCopyRef}
        disabled={disabled}
        firstIcon={{ children: 'copy_all' }}
        size="sm"
        variant="secondary"
        onMouseEnter={() => setShowPopoverCopy(true)}
        onMouseLeave={() => setShowPopoverCopy(false)}
        onClick={() => {
          navigator.clipboard.writeText(text);
          setCopied(true);
        }}
      />

      <Popover
        propRef={buttonTooltipCopyRef}
        showPopover={showPopoverCopy}
        callback={() => setShowPopoverCopy(false)}
        showArrow
        color="dark"
        hiddenOverlay
        mainStyle={{ textAlign: 'center', minWidth: '8.75rem' }}
      >
        {copied ? onClickMessage || 'Copied!' : hoverMessage || 'Copy to clipboard'}
      </Popover>
    </>
  );
}
