import { forwardRef } from 'react';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import { FontWeight, ParagraphyProps } from '../interface';
import styles from './styles.module.scss';

const Paragraph = forwardRef<HTMLParagraphElement, ParagraphyProps>(
  ({ size, weight, color, children, ellipsis, transform, mb, onMouseEnter, onMouseLeave }: ParagraphyProps, ref) => {
    return (
      <p
        ref={ref}
        className={styleModuleClasses(styles, {
          p1: size === 1,
          p2: size === 2,
          p3: size === 3,
          mb2: mb === 2,
          'ellipsis-1': ellipsis === 1,
          'ellipsis-2': ellipsis === 2,
          [color as string]: !!color,
          [weight as string]: !!weight,
          [FontWeight.regular]: !weight,
        })}
        {...(onMouseEnter && { onMouseEnter: onMouseEnter })}
        {...(onMouseLeave && { onMouseLeave: onMouseLeave })}
        style={{ textTransform: transform }}
      >
        {children}
      </p>
    );
  },
);

Paragraph.displayName = 'Pragraph';

export { Paragraph };
