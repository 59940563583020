import { TableHeaderElement } from '../../../../../../componentsV2/TableColumnsControl/interface';

export const DEFAULT_COLUMNS: TableHeaderElement[] = [
  {
    id: '9990',
    name: 'Name',
    base_field: 'name',
    type: 'name',
    enabled: true,
    sortDisabled: true,
    order: 1,
    is_uid_field: false,
    width: '12rem',
    align: 'left',
    is_optin: false,
    transform: 'capitalize',
  },
  {
    id: '9991',
    name: 'Promotion',
    base_field: 'promotion_name',
    type: 'tag',
    enabled: true,
    sortDisabled: false,
    order: 2,
    is_uid_field: false,
    width: '12rem',
    align: 'left',
    is_optin: false,
    transform: 'capitalize',
  },
  {
    id: '9992',
    name: 'Entry Date',
    base_field: 'completed_at',
    type: 'date',
    enabled: true,
    sortDisabled: false,
    order: 10,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
    is_optin: false,
  },
  {
    id: '9993',
    name: 'Game Type',
    base_field: 'game_type_title',
    type: 'text',
    enabled: true,
    sortDisabled: false,
    order: 3,
    is_uid_field: false,
    width: '12rem',
    align: 'left',
    is_optin: false,
    transform: 'capitalize',
  },
  {
    id: '9994',
    base_field: 'email',
    type: 'text',
    enabled: true,
    name: 'Email',
    sortDisabled: false,
    order: 8,
    width: '12rem',
    align: 'left',
    is_optin: false,
  },
  {
    id: '9995',
    name: 'Entry Method',
    base_field: 'visibility',
    type: 'pill',
    enabled: true,
    sortDisabled: false,
    order: 4,
    is_uid_field: false,
    width: '12rem',
    align: 'center',
    is_optin: false,
    transform: 'capitalize',
  },
  {
    id: '9996',
    name: 'Result Label',
    base_field: 'outcome_title',
    type: 'text',
    enabled: true,
    sortDisabled: false,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
    is_optin: false,
    transform: 'capitalize',
  },
  {
    id: '9997',
    name: 'Reward Label',
    base_field: 'segment_label',
    type: 'text',
    enabled: true,
    sortDisabled: false,
    order: 6,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
    is_optin: false,
    transform: 'capitalize',
  },
  {
    id: '9998',
    name: 'Terms and Conditions',
    base_field: 'term',
    type: 'pill',
    enabled: true,
    sortDisabled: false,
    order: 6,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
    is_optin: false,
  },
  {
    id: '9999',
    name: 'Newsletter',
    base_field: 'newsletter',
    type: 'pill',
    enabled: true,
    sortDisabled: false,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
    is_optin: false,
  },
  {
    id: '10000',
    name: 'Invite Code',
    base_field: 'invite_code',
    type: 'text',
    enabled: true,
    sortDisabled: false,
    order: 4,
    is_uid_field: false,
    width: '15rem',
    align: 'left',
    is_optin: false,
  },
  {
    id: '10001',
    name: 'Draft Entry',
    base_field: 'status_promo',
    type: 'pill',
    enabled: true,
    sortDisabled: false,
    order: 5,
    is_uid_field: false,
    width: '12rem',
    align: 'center',
    is_optin: false,
  },
  {
    id: '10002',
    name: 'Opened Date',
    base_field: 'created_at',
    type: 'date',
    enabled: true,
    sortDisabled: false,
    order: 9,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
    is_optin: false,
  },
];
