import { ApolloError, useQuery } from '@apollo/client';
import useFetchPromotionsSimpleList from '../../../../graphql/queries/useFetchPromotionsSimpleList';
import useAccount from '../../../../hooks/useAccount';
import useQueryParams from '../../../../hooks/useQueryParams';
import useQueryString from '../../../../hooks/useQueryString';
import { paginatorInfo } from '../../../../ts';
import { Campaigns } from '../../CreatePromotion/SectionCampaigns/interface';
import Filters from '../Filters';
import FILTER_LIST from '../Filters/DefaultFilterList';
import { ENTRIES_FILTER_LIST } from '../Filters/FilterList';
import Content from './Content';
import { CAMPAIGNS } from './entries.gql';
import styles from './styles.module.scss';
import { EntriesFilters, useFetchEntries } from './useFetchEntries';

export default function Entries(): JSX.Element {
  const { getAccount } = useAccount();
  const query = useQueryString();
  const addQueryParam = useQueryParams();

  const { data: promotionData, loading: promotionLoading } = useFetchPromotionsSimpleList(getAccount.accountId);
  const { data: gameTypeData, loading: gameTypeLoading } = useQuery<{
    campaigns: { data: Campaigns[]; paginatorInfo: paginatorInfo };
  }>(CAMPAIGNS, {
    variables: { accountId: getAccount.accountId, page: 1, pageLimit: 100 },
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      console.log({
        gql: 'CAMPAIGNS',
        apolloError: error,
      });
    },
  });

  const queryValues = {
    sort_by: query.get('sort_by') || 'created_at',
    sort_order: query.get('sort_order') || 'desc',
    currentPage: Number(query.get('page')) || 1,
    tableColumns: query.get('columns') || undefined,
  };
  const newDate = new Date();
  const filters = {
    search: query.get('search') || undefined,
    promo_id: query.get('promo_id') || ' ',
    date: query.get('date') || ' ',
    status: query.get('status') || 'live,ended',
    visibility: query.get('visibility') || ' ',
    game_type_slug: query.get('game_type_slug') || ' ',
    created_at:
      query.get('created_at') || `2021-01-01_${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`,
    completed_at: query.get('completed_at') || undefined,
    term: query.get('term') || ' ',
    newsletter: query.get('newsletter') || ' ',
  };

  const params: EntriesFilters = {
    sort_by: queryValues.sort_by,
    sort_order: queryValues.sort_order,
    ...filters,
  };

  const {
    data,
    loading: dataLoading,
    pagination,
    fetchEntries,
  } = useFetchEntries(getAccount.accountId, { page: queryValues.currentPage }, params);

  const handlePagination = (page: number) => {
    addQueryParam({ page });
  };

  const searchEntries = (field: string, value: string) => {
    const searchFilter = value ? { [field]: value } : { [field]: '' };
    const filters = {
      ...searchFilter,
    };
    addQueryParam({ ...filters, page: 1 });
  };

  const clearUrlFilters = () => {
    addQueryParam({
      search: undefined,
      promo_id: undefined,
      date: undefined,
      status: undefined,
      visibility: undefined,
      game_type_slug: undefined,
      created_at: undefined,
      completed_at: undefined,
      expired_at: undefined,
      term: undefined,
      newsletter: undefined,
      page: 1,
    });
  };

  return (
    <section className={styles['wrapper']}>
      {/*@todo <Button onClick={() => fetchEntries({ accountId: getAccount.accountId })}>Refetch</Button> */}
      <Filters
        fetchLoading={dataLoading}
        search={searchEntries}
        params={params}
        clearUrlFilters={clearUrlFilters}
        data={promotionData}
        loadingData={promotionLoading}
        gameTypeData={gameTypeData?.campaigns.data}
        gameTypeLoading={gameTypeLoading}
        filterList={[...FILTER_LIST, ...ENTRIES_FILTER_LIST]}
        filterKey={`entries_${getAccount.accountId}`}
      />
      <Content
        data={data}
        dataLoading={dataLoading}
        refetch={fetchEntries}
        pagination={pagination}
        handlePagination={handlePagination}
        currentPage={queryValues.currentPage}
        search={searchEntries}
        params={params}
      />
    </section>
  );
}
